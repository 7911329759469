import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import { AuthContext } from '../contexts/AuthContext';
import { deleteAuthStorage } from "../utils/auth";

import ReactGA from 'react-ga4';

const Dashboard = () => {
    ReactGA.send({
        hitType: "pageview",
        page: '/dashboard',
        title: 'Dashboard'
    })

    const navigate = useNavigate();
    const { user, loading } = useContext(AuthContext);

    if (loading) {
        return <div>Loading...</div>;
    }

    // Retrieve access token from local storage
    const accessToken = localStorage.getItem('access_token');
    const iframeSrc = `${process.env.REACT_APP_IFRAME_URL}?token=${accessToken}`;

    const handleLogout = () => {
        deleteAuthStorage()
        navigate('/login');
    };

    return (
        <div className="min-h-screen flex flex-col bg-gray-100">
            <div className="absolute top-0 left-0 p-4 flex items-center space-x-4">
                <button
                    onClick={handleLogout}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                >
                    Logout
                </button>
                {/*<div className="text-gray-800 font-medium">*/}
                {/*    Credits: {user.credits}*/}
                {/*</div>*/}
            </div>
            <div className="flex-grow flex justify-center items-center">
                <iframe
                    src={iframeSrc}
                    title="Example Website"
                    className="aspect-video w-11/12 border rounded"
                ></iframe>
            </div>
        </div>
    );
};

export default Dashboard;