import axios from 'axios';
import { deleteAuthStorage } from "./utils/auth";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000,
    headers: {
        Authorization: localStorage.getItem('access_token')
            ? 'Bearer ' + localStorage.getItem('access_token')
            : null,
        'Content-Type': 'application/json',
        accept: 'application/json',
    },
});

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Prevent infinite loop for token refresh
        if (
            error.response.status === 401 &&
            originalRequest.url === `${process.env.REACT_APP_API_URL}/auth/token/refresh/`
        ) {
            window.location.href = '/login';
            return Promise.reject(error);
        }

        // Attempt to refresh token if unauthorized error occurs
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refresh_token');

            if (refreshToken) {
                return axiosInstance
                    .post('/auth/token/refresh/', { refresh: refreshToken })
                    .then((response) => {
                        localStorage.setItem('access_token', response.data.access);
                        axiosInstance.defaults.headers['Authorization'] =
                            'Bearer ' + response.data.access;
                        originalRequest.headers['Authorization'] =
                            'Bearer ' + response.data.access;
                        return axiosInstance(originalRequest);
                    })
                    .catch((err) => {
                        console.error('Error refreshing token:', err);
                        originalRequest._retry = false;
                        deleteAuthStorage()
                        window.location.href = '/login';
                        return Promise.reject(err);
                    });
            } else {
                console.log('Refresh token not available');
                originalRequest._retry = false;
                deleteAuthStorage()
                window.location.href = '/login';
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;